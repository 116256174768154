import { DateTime } from "luxon";
import React from "react";

const Loading: React.FC<any> = ({ className, countDownSeconds }) => {
  const [secondsRemaining, setSecondsRemaining] =
    React.useState(countDownSeconds);

  React.useEffect(() => {
    if (countDownSeconds) {
      const interval = setInterval(() => {
        const now = DateTime.now();
        const remaining = countDownSeconds--;
        if (remaining <= 0) {
          clearInterval(interval);
          return;
        }
        setSecondsRemaining(remaining);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [countDownSeconds]);

  return (
    <div
      className={`flex flex-col gap-4 items-center justify-center ${className}`}
    >
      <svg
        className="animate-spin h-16 w-16 text-blue-500"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8v8H4z"
        ></path>
      </svg>
      {secondsRemaining && (
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          className="text-black"
        >
          {secondsRemaining}
        </text>
      )}
    </div>
  );
};

export default Loading;
