import {
  BuildingOfficeIcon,
  BuildingStorefrontIcon,
  ClipboardDocumentCheckIcon,
  DocumentChartBarIcon,
  DocumentIcon,
  EnvelopeIcon,
  FolderIcon,
  PresentationChartBarIcon,
  QueueListIcon,
  RocketLaunchIcon,
  TrophyIcon,
  UserCircleIcon,
  UserGroupIcon,
  UsersIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";

export const userListNavigation = [
  {
    name: "Create New User",
    href: "/admin/user/",
    icon: UsersIcon,
  },
  {
    name: "All Users",
    href: "/admin/users/all",
    icon: UsersIcon,
  },
  {
    name: "Portfolio Users",
    href: "/admin/users/portfolio",
    icon: UsersIcon,
  },
  {
    name: "Sponsor Users",
    href: "/admin/users/sponsor",
    icon: UsersIcon,
  },
  {
    name: "Investor Users",
    href: "/admin/users/investor",
    icon: UsersIcon,
  },
  {
    name: "Ecosystem Users",
    href: "/admin/users/ecosystem",
    icon: UserGroupIcon,
  },
  {
    name: "Merge Duplicate Users",
    href: "/admin/manage-users",
    icon: UsersIcon,
  },
  {
    name: "All Pitch Event Judges",
    href: "/judges/all",
    icon: UsersIcon,
  },
];

export const companyNavigation = [
  {
    name: "Companies",
    href: "/company/all",
    icon: BuildingOfficeIcon,
  },
  {
    name: "Alumni Milestones",
    href: "/milestones/all",
    icon: TrophyIcon,
  },
  {
    name: "User Access Requests",
    href: "/admin/access-requests",
    icon: UsersIcon,
  },
  {
    name: "Nominations",
    href: "/admin/nominations",
    icon: TrophyIcon,
  },
  {
    name: "Airtable PortCo Sync",
    href: "/admin/users/airtable-portfolio",
    icon: UsersIcon,
  },
  {
    name: "APAC Cohort Selection Diligence",
    href: "lists/229/program-application/2/company/6549",
    icon: QueueListIcon,
  },
  {
    name: "MTI Cohort Selection Diligence",
    href: "lists/230/program-application/3/company/6549",
    icon: QueueListIcon,
  },
  {
    name: "BTI Cohort Selection Diligence",
    href: "lists/231/program-application/4/company/6549",
    icon: QueueListIcon,
  },
  {
    name: "Merge Duplicate Companies",
    href: "/admin/manage-companies",
    icon: BuildingOfficeIcon,
  },
];

export const applicationUtilityNavigation = [
  {
    name: "Confidential Files",
    href: "/admin/confidential-files",
    icon: FolderIcon,
  },
  {
    name: "List Management",
    href: "/admin/list-management",
    icon: QueueListIcon,
  },
  {
    name: "Reviewer Users",
    href: "/admin/reviewer-users",
    icon: UserCircleIcon,
    current: false,
  },
  {
    name: "Reviews by Program",
    href: "/admin/reviewer-companies",
    icon: BuildingStorefrontIcon,
  },
  {
    name: "Review / Curation Totals by Partner",
    href: "/admin/partner-reviews",
    icon: ClipboardDocumentCheckIcon,
  },
  {
    name: "Additional Information to Email to Applicants",
    href: "/admin/email-applicants",
    icon: RocketLaunchIcon,
  },
];

export const adminNavigation = [
  {
    name: "Event Management",
    href: "/events/all",
    icon: PresentationChartBarIcon,
  },
  {
    name: "Company Diligence",
    href: "/admin/diligence",
    icon: DocumentChartBarIcon,
  },
  {
    name: "Video Management",
    href: "/admin/videos",
    icon: VideoCameraIcon,
  },
  {
    name: "Portfolio Video List",
    href: "/admin/portfolio/videos",
    icon: VideoCameraIcon,
  },
  // {
  //   name: "Marketing",
  //   href: "/admin/marketing",
  //   icon: FolderIcon,
  // },
  {
    name: "Pitchbook CSV Import",
    href: "/admin/pitchbook",
    icon: DocumentChartBarIcon,
  },
  {
    name: "Sent Email Statistics",
    href: "/admin/email-statistics",
    icon: EnvelopeIcon,
  },
  {
    name: "Blast Sender - MTI 2025",
    href: "https://medtechinnovator.org/laravel/email/list/2025",
    icon: RocketLaunchIcon,
  },
  {
    name: "Blast Sender - APAC 2025",
    href: "https://medtechinnovator.org/laravel/email/list/asia2025",
    icon: RocketLaunchIcon,
  },
  {
    name: "Blast Sender - BTI 2025",
    href: "https://medtechinnovator.org/laravel/email/list/biotools2025",
    icon: RocketLaunchIcon,
  },
];
