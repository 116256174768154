"use client";

import Layout from "./components/Layout";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/navigation";
import {
  EventUserTypeOptions,
  PitchEvent,
  ProgramApplicationMappingIdToProgramOptions,
  ProgramApplicationTitles,
  ReviewerPermissionLevels,
} from "@/app/types";
import InvestorMarketingSection from "./components/marketing/InvestorMarketing";
import { isPortfolioCompanyUserOrPartner, UserContext } from "./utils/user";
import withNoSSR from "./withNoSSR";
import {
  CheckIcon,
  IdentificationIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import { RoleOptions } from "./types";
import { classNames } from "./utils/tailwindHelper";
import Link from "next/link";
import { showNewMessage } from "@intercom/messenger-js-sdk";
import OmniSearch from "./components/search/OmniSearch";
import { makeAPIRequest } from "@/app/api/apiWrapper";
import PageLoading from "./components/PageLoading";
import { DateTime } from "luxon";
import {
  adminNavigation,
  applicationUtilityNavigation,
  companyNavigation,
  userListNavigation,
} from "./navigation/options";

const getProgramBackgroundColor = (program: ProgramApplicationTitles) => {
  switch (program) {
    case "MedTech Innovator - Accelerator":
      return "border border-mti-darkblue bg-mti_blue_bg";
    case "MedTech Innovator APAC - Accelerator":
      return "border border-apac-purple bg-apac_purple_bg";
    case "BioTools Innovator VANGUARD Funding Solicitation":
    case "BioTools Innovator - Accelerator":
      return "border border-bti-dark-green bg-bti_green_bg";
    default:
      return "border border-grey bg-grey-100";
  }
};
const getProgramBackgroundColorById = (programApplicationId: number) => {
  switch (programApplicationId) {
    case 3:
      return "bg-mti_blue_bg";
    case 2:
      return "bg-apac_purple_bg";
    case 4:
      return "bg-bti_green_bg";
    default:
      return "";
  }
};

const Home = function () {
  const router = useRouter();
  const user = useContext(UserContext).user;
  const reloadUser = useContext(UserContext).reloadUser;
  const [loading, setLoading] = useState(true);

  if (!user) {
    router.push("/login");
  }

  let programsReviewingFromUser: any[] = [];
  if (user?.reviewerConfiguration) {
    const keys = Object.keys(user.reviewerConfiguration);
    const titlesToIds = Object.entries(
      ProgramApplicationMappingIdToProgramOptions
    ).reduce((acc: any, [k, v]) => {
      acc[v] = k;
      return acc;
    }, {});

    programsReviewingFromUser = keys.map((key) => {
      if (user?.reviewerConfiguration) {
        return {
          id: titlesToIds[key],
          title: key,
          permission: (user.reviewerConfiguration as any)[key].permission,
          assignmentsPerCycle: (user.reviewerConfiguration as any)[key]
            .assignmentsPerCycle,
          phrases: (user.reviewerConfiguration as any)[key].phrases,
          interestsConfirmed: (user.reviewerConfiguration as any)[key]
            .interestsConfirmed,
          noPreference: (user.reviewerConfiguration as any)[key].noPreference,
        };
      }
    });
  }
  if (user?.role === RoleOptions.Admin) {
    programsReviewingFromUser = [];
    Object.entries(ProgramApplicationMappingIdToProgramOptions).map(
      ([k, v]) => {
        programsReviewingFromUser.push({
          id: k,
          title: v,
          permission: ReviewerPermissionLevels.SelectionReviewer,
        });
      }
    );
  }

  const [programsReviewing, setProgramsReviewing] = useState<any[]>(
    programsReviewingFromUser
  );
  const [showDeadlines, setShowDeadlines] = useState<boolean>(true);
  const [pitchEvents, setPitchEvents] = useState<any[]>([]);

  const [listsReviewing, setListsReviewing] = useState<any[]>([]);
  useEffect(() => {
    reloadUser();
  }, []);
  useEffect(() => {
    const loadPitchEvents = async () => {
      const response: any = await makeAPIRequest(`pitch-event/invites`, "GET");
      setPitchEvents(response);
    };

    const loadUserStats = async () => {
      const response: any = await makeAPIRequest(
        `program-applications/user/${user?.id}/submissions`,
        "GET"
      );
      setShowDeadlines(
        !response.otherLists.some((list: any) => {
          return ["ASPS"].includes(list.name);
        })
      );
      const isMTISelectionReviewer = programsReviewing.some(
        (program) =>
          +program.id === 3 &&
          program.permission ===
            ReviewerPermissionLevels.LimitedSelectionReviewer
      );

      // only include ASPS list if they are also a selection reviewer
      let otherLists = response.otherLists.filter((list: any) => {
        return !["ASPS"].includes(list.name) || isMTISelectionReviewer;
      });

      // all lists deduped by id
      let uniqueLists = [...otherLists, ...response.companyLists].reduce(
        (acc: any[], list: any) => {
          if (!acc.some((l) => l.id === list.id)) {
            acc.push(list);
          }
          return acc;
        },
        []
      );

      // // only include AHA list if they are also a selection reviewer
      // uniqueLists = uniqueLists.filter((list: any) => {
      //   return (
      //     !["American Heart Association"].includes(list.name) ||
      //     isMTISelectionReviewer
      //   );
      // });

      setListsReviewing(uniqueLists);
    };

    const loadApplicationStats = async () => {
      const response: any[] = await makeAPIRequest(
        "applications?aggregate=stats",
        "GET",
        undefined,
        () => {
          // do nothing error
        }
      );

      if (response) {
        programsReviewing.forEach((program) => {
          const programApplication = response.find(
            (app) => app.id == +program.id
          );
          if (programApplication) {
            program.total = programApplication.total;
            program.assigned = programApplication.assigned;
            program.submitted = programApplication.submitted;
            program.reviewed = programApplication.reviewed;
            program.reviewersStats = programApplication.reviewersStats;
            programApplication.pitchEvents.sort((a: any, b: any) =>
              a.partnerDeadlineDate < b.partnerDeadlineDate ? -1 : 1
            );

            program.pitchEvents = programApplication.pitchEvents;
            program.logoUrl = programApplication.logoUrl;
          }
        });
      }
      setProgramsReviewing([...programsReviewing]);
    };
    // only reviews need this stuff
    if (Object.keys(programsReviewing).length) {
      loadApplicationStats();
      loadUserStats();
    }
    loadPitchEvents();
  }, []);

  useMemo(async () => {
    if (user) {
      if (!user.company) {
        const accessRequest = await makeAPIRequest(
          "user/me/company-access-request",
          "GET"
        );
        if (accessRequest) {
          router.replace("/company/onboarding/access-requested");
        } else {
          router.replace("/company/onboarding");
        }
      }
    }
    setLoading(false);
  }, []);

  return (
    <>
      {!loading && user ? (
        <main>
          <Layout>
            <div className="flex flex-col mt-12">
              <div className="flex justify-center text-2xl">
                Welcome {user?.firstName}
              </div>
              {user.role !== RoleOptions.Admin && (
                <>
                  {programsReviewing.length > 0 && (
                    <div className="mx-auto px-2">
                      {programsReviewing.map((programReviewing, index) => (
                        <div key={index}>
                          You are a reviewer for {programReviewing.title}
                        </div>
                      ))}

                      <p className="flex justify-center pt-2">
                        Need help getting started? Please visit the
                        <Link
                          href={
                            "https://help.innovator.org/en/articles/10354718-application-reviewer-setup"
                          }
                          className="underline text-blue-500 pl-1"
                        >
                          Reviewer Guide
                        </Link>
                        .
                      </p>
                    </div>
                  )}
                </>
              )}
              {!user.role && (
                <div className="mx-auto pt-8 max-w-2xl text-center">
                  Your user account is not yet fully configured. Please reach
                  out using the{" "}
                  <a
                    className="font-semibold text-blue-600 hover:text-blue-500 hover:cursor-pointer"
                    onClick={() => {
                      showNewMessage("Hi, I need help with my user setup.");
                    }}
                  >
                    support chat
                  </a>{" "}
                  or email{" "}
                  <a
                    href="mailto:support@medtechinnovator.org"
                    className="font-semibold text-blue-600 hover:text-blue-500"
                  >
                    support@medtechinnovator.org
                  </a>
                  .
                </div>
              )}
              {user?.role == RoleOptions.Admin && (
                <div className="flex mx-auto flex-col items-center">
                  <div className="w-72">
                    <OmniSearch />
                  </div>
                  <div className="mx-auto max-w-7xl px-6 pt-8 lg:px-8">
                    <div className="space-y-16 sm:grid sm:grid-cols-8 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
                      <div className="col-span-2 border rounded-xl p-2">
                        <h3 className="text-base font-semibold text-gray-900">
                          User Navigation
                        </h3>

                        <nav className="" aria-label="Sidebar">
                          <ul role="list" className="">
                            {userListNavigation.map((item, index) => (
                              <li key={index}>
                                <a
                                  href={item.href}
                                  className={
                                    "flex text-gray-700 hover:text-blue-600 hover:bg-gray-50 group gap-x-3 rounded-md p-2 text-sm/6"
                                  }
                                >
                                  <item.icon
                                    className={
                                      "text-gray-400 group-hover:text-blue-600h-6 w-6 shrink-0"
                                    }
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </div>
                      <div className="col-span-2 border rounded-xl p-2">
                        <h3 className="text-base font-semibold text-gray-900">
                          Company Navigation
                        </h3>
                        <nav className="" aria-label="Sidebar">
                          <ul role="list" className="">
                            {companyNavigation.map((item, index) => (
                              <li key={index}>
                                <a
                                  href={item.href}
                                  className={
                                    "flex text-gray-700 hover:text-blue-600 hover:bg-gray-50 group gap-x-3 rounded-md p-2 text-sm/6"
                                  }
                                >
                                  <item.icon
                                    className={
                                      "text-gray-400 group-hover:text-blue-600h-6 w-6 shrink-0"
                                    }
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </div>
                      <div className="col-span-2 border rounded-xl p-2">
                        <h3 className="text-base font-semibold text-gray-900">
                          Application Utility Navigation
                        </h3>
                        <nav className="" aria-label="Sidebar">
                          <ul role="list" className="">
                            {applicationUtilityNavigation.map((item, index) => (
                              <li key={index}>
                                <a
                                  href={item.href}
                                  className={
                                    "flex text-gray-700 hover:text-blue-600 hover:bg-gray-50 group gap-x-3 rounded-md p-2 text-sm/6"
                                  }
                                >
                                  <item.icon
                                    className={
                                      "text-gray-400 group-hover:text-blue-600h-6 w-6 shrink-0"
                                    }
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </div>
                      <div className="col-span-2 border rounded-xl p-2">
                        <h3 className="text-base font-semibold text-gray-900">
                          Other Navigation
                        </h3>

                        <nav className="" aria-label="Sidebar">
                          <ul role="list" className="">
                            {adminNavigation.map((item, index) => (
                              <li key={index}>
                                <a
                                  href={item.href}
                                  className={
                                    "flex text-gray-700 hover:text-blue-600 hover:bg-gray-50 group gap-x-3 rounded-md p-2 text-sm/6"
                                  }
                                >
                                  <item.icon
                                    className={
                                      "text-gray-400 group-hover:text-blue-600h-6 w-6 shrink-0"
                                    }
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>

                  {/* <Link href="/admin">Admin</Link> */}
                </div>
              )}

              {pitchEvents.length > 0 && (
                <div className="mx-auto px-2">
                  <div className="mx-auto max-w-7xl my-8 px-6 py-8 lg:px-8 border rounded-xl">
                    <div className="space-y-16 gap-x-2 lg:grid lg:grid-cols-6 lg:gap-y-16 lg:space-y-0 lg:gap-x-10">
                      {pitchEvents.map((pitchEvent, index) => (
                        <Fragment key={index}>
                          <div
                            className={classNames(
                              pitchEvents.length === 1 ? "col-start-3" : "",
                              `col-span-2 min-h-[176px] 2xl:min-w-[383px] p-4 rounded-lg ${getProgramBackgroundColor(
                                pitchEvent.programApplication.title as any
                              )}`
                            )}
                          >
                            {pitchEvent.programApplication.logoUrl && (
                              <img
                                src={pitchEvent.programApplication.logoUrl}
                                className="max-h-16 px-4 mx-auto"
                              ></img>
                            )}
                            <h3 className="text-base font-semibold text-gray-900">
                              {pitchEvent.shortLabel}
                            </h3>
                            <div className="py-2">
                              {pitchEvent.eventUserType !==
                                EventUserTypeOptions.PitchCompany &&
                                pitchEvent.judgePageUrl && (
                                  <Link
                                    href={pitchEvent.judgePageUrl}
                                    className="text-blue-600 underline"
                                  >
                                    Event Detail Page
                                  </Link>
                                )}
                              {pitchEvent.eventUserType ===
                                EventUserTypeOptions.PitchCompany &&
                                pitchEvent.startupPageUrl && (
                                  <Link
                                    href={pitchEvent.startupPageUrl}
                                    className="text-blue-600 underline"
                                  >
                                    Event Detail Page
                                  </Link>
                                )}
                            </div>
                            <div className="flex flex-col text-sm">
                              {pitchEvent.eventUserType ===
                                EventUserTypeOptions.PitchJudge && (
                                <Link
                                  href={`/events/${pitchEvent.id}/pitch-report`}
                                  className="text-blue-600 underline"
                                >
                                  Background Report
                                </Link>
                              )}
                              <Link
                                href={`/events/${pitchEvent.id}/biobook`}
                                className="text-blue-600 underline"
                              >
                                BioBook
                              </Link>
                              <Link
                                href={`/events/${pitchEvent.id}/rsvp`}
                                className="text-blue-600 underline"
                              >
                                Your RSVP
                              </Link>
                              {[
                                EventUserTypeOptions.PitchJudge,
                                EventUserTypeOptions.PitchCompany,
                              ].includes(pitchEvent.eventUserType) && (
                                <Link
                                  href={`/events/${pitchEvent.id}/user/${user.id}/schedule`}
                                  className="text-blue-600 underline"
                                >
                                  Your Schedule
                                </Link>
                              )}
                              {pitchEvent.eventUserType ===
                                EventUserTypeOptions.PitchCompany && (
                                <Link
                                  href={`/events/${pitchEvent.id}/user/${user.id}/feedback`}
                                  className="text-blue-600 underline"
                                >
                                  Your Scores
                                </Link>
                              )}
                              {pitchEvent.eventUserType ===
                                EventUserTypeOptions.PitchJudge && (
                                <Link
                                  href={`/events/${pitchEvent.id}/user/${user.id}/scores`}
                                  className="text-blue-600 underline"
                                >
                                  Your Scores
                                </Link>
                              )}
                            </div>
                          </div>
                        </Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {programsReviewing.length > 0 && (
                <div className="mx-auto px-2">
                  <div className="mx-auto max-w-7xl px-6 py-8 lg:px-8 border rounded-xl">
                    <div className="space-y-8 lg:grid lg:grid-cols-6 lg:gap-y-8 lg:space-y-0 lg:gap-x-8">
                      {false && (
                        <div
                          className={`col-span-2 min-h-[176px] border ${getProgramBackgroundColor(
                            "programReviewing.title" as any
                          )} p-4 rounded-lg`}
                        >
                          <h3 className="text-base font-semibold text-gray-900">
                            2025 Roadtour Pitch Events
                          </h3>

                          {true && (
                            <div className="text-red-500 font-medium">
                              Action Required
                            </div>
                          )}
                          <p className="mt-2">
                            <Link
                              href="https://medtechinnovator.org/roadtour-overview-2025/"
                              className="underline text-blue-500 text-sm"
                            >
                              View information about Roadtour Pitch Events
                            </Link>
                          </p>
                          <p className="mt-2 text-sm">
                            We do not yet have your availability and/or
                            preferences for the{" "}
                            <strong>West Coast Pitch Event</strong>. Click{" "}
                            <Link className="underline text-blue-500" href="/">
                              here
                            </Link>{" "}
                            to inform us now.
                          </p>
                          <p className="underline text-blue-500 text-sm mt-2">
                            <Link className="underline text-blue-500" href="/">
                              View BioBook of all confirmed attendees
                            </Link>
                          </p>
                        </div>
                      )}
                      {programsReviewing.map((programReviewing, index) => (
                        <Fragment key={index}>
                          <div
                            className={classNames(
                              user.role === RoleOptions.Admin
                                ? "col-start-auto"
                                : "",
                              `col-span-2 min-h-[176px] p-4 rounded-lg ${getProgramBackgroundColor(
                                programReviewing.title
                              )}`
                            )}
                          >
                            {programReviewing.logoUrl && (
                              <img
                                src={programReviewing.logoUrl}
                                className="max-h-16 px-4 mx-auto"
                              ></img>
                            )}
                            <h3 className="text-base font-semibold text-gray-900">
                              <Link
                                href={`/user/${user.id}/applications/${programReviewing.id}`}
                              >
                                {programReviewing.assigned > 0 && (
                                  <span>Assigned</span>
                                )}{" "}
                                Applications
                              </Link>
                            </h3>
                            {!programReviewing.logoUrl && (
                              <p className="text-sm text-gray-700">
                                {programReviewing.title}
                              </p>
                            )}
                            {showDeadlines && (
                              <>
                                {[3].includes(+programReviewing.id) && (
                                  <>
                                    {programReviewing.permission ===
                                    ReviewerPermissionLevels.Reviewer ? (
                                      <div className="italic text-sm">
                                        Review these applications by{" "}
                                        <strong>
                                          {DateTime.now()
                                            .endOf("week")
                                            .toFormat("MMM d")}
                                        </strong>
                                      </div>
                                    ) : (
                                      <div className="text-sm">
                                        <div>
                                          Please review applications for each
                                          pitch event
                                        </div>
                                        {programReviewing.pitchEvents
                                          ?.filter(
                                            (pitchEvent: any) =>
                                              pitchEvent.label
                                          )
                                          .map(
                                            (
                                              pitchEvent: any,
                                              index: number
                                            ) => (
                                              <Fragment key={index}>
                                                <div className="font-bold pt-1">
                                                  {pitchEvent.label}
                                                </div>
                                                <ul className="italic list-disc list-inside pl-4">
                                                  <li key={index}>
                                                    <Link
                                                      href={`/user/${
                                                        user.id
                                                      }/applications/${
                                                        programReviewing.id
                                                      }?pitchEvent=${encodeURIComponent(
                                                        pitchEvent.name
                                                      )}`}
                                                      className="text-blue-600 underline"
                                                    >
                                                      Review Deadline:{" "}
                                                      {DateTime.fromSQL(
                                                        pitchEvent.partnerDeadlineDate
                                                      ).toFormat("LLLL d")}
                                                    </Link>
                                                  </li>
                                                </ul>
                                              </Fragment>
                                            )
                                          )}
                                      </div>
                                    )}
                                  </>
                                )}
                                {[4].includes(+programReviewing.id) && (
                                  <>
                                    {programReviewing.permission ===
                                    ReviewerPermissionLevels.Reviewer ? (
                                      <div className="italic text-sm">
                                        Review these applications by{" "}
                                        <strong>February 17</strong>
                                      </div>
                                    ) : (
                                      <div className="text-sm">
                                        <div>
                                          Please review applications for each
                                          pitch event
                                        </div>
                                        {programReviewing.pitchEvents
                                          ?.filter(
                                            (pitchEvent: any) =>
                                              pitchEvent.label
                                          )
                                          .map(
                                            (
                                              pitchEvent: any,
                                              index: number
                                            ) => (
                                              <Fragment key={index}>
                                                <div className="font-bold pt-1">
                                                  {pitchEvent.label}
                                                </div>
                                                <ul className="italic list-disc list-inside pl-4">
                                                  <li key={index}>
                                                    <Link
                                                      href={`/user/${
                                                        user.id
                                                      }/applications/${
                                                        programReviewing.id
                                                      }?pitchEvent=${encodeURIComponent(
                                                        pitchEvent.name
                                                      )}`}
                                                      className="text-blue-600 underline"
                                                    >
                                                      Review Deadline:{" "}
                                                      {DateTime.fromSQL(
                                                        pitchEvent.partnerDeadlineDate
                                                      ).toFormat("LLLL d")}
                                                    </Link>
                                                  </li>
                                                </ul>
                                              </Fragment>
                                            )
                                          )}
                                      </div>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                              {user.role === RoleOptions.Admin && (
                                <div className="overflow-hidden rounded-lg bg-white p-2 shadow">
                                  <dt className="text-sm font-medium text-gray-500">
                                    Total Applications
                                  </dt>
                                  <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                    {programReviewing.total}
                                  </dd>
                                </div>
                              )}

                              <div className="overflow-hidden rounded-lg bg-white p-2 shadow">
                                <dt className="text-sm font-medium text-gray-500">
                                  Available for Review
                                </dt>
                                {[
                                  ReviewerPermissionLevels.FullReviewer,
                                  ReviewerPermissionLevels.SelectionReviewer,
                                  ReviewerPermissionLevels.VotingReviewer,
                                ].includes(programReviewing.permission) ? (
                                  <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                    <Link
                                      href={`/user/${user.id}/applications/${programReviewing.id}`}
                                      className="underline text-blue-500"
                                    >
                                      {programReviewing.submitted}
                                    </Link>
                                  </dd>
                                ) : (
                                  <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                    {programReviewing.submitted}
                                  </dd>
                                )}
                              </div>
                              {programReviewing.assigned > 0 && (
                                <>
                                  <div className="overflow-hidden rounded-lg bg-white p-2 shadow hover:bg-gray-50">
                                    <Link
                                      href={`/user/${user.id}/applications/${
                                        programReviewing.id
                                      }?assignedTo=${encodeURIComponent(
                                        user.firstName + " " + user.lastName
                                      )}`}
                                    >
                                      <dt className="text-sm font-medium text-gray-500">
                                        Assigned to You
                                      </dt>
                                      <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                        <div className="underline text-blue-500">
                                          {programReviewing.assigned}
                                        </div>
                                      </dd>
                                    </Link>
                                  </div>
                                  <div className="overflow-hidden rounded-lg bg-white p-2 shadow hover:bg-gray-50">
                                    <Link
                                      href={`/user/${user.id}/applications/${
                                        programReviewing.id
                                      }?assignedTo=${encodeURIComponent(
                                        user.firstName + " " + user.lastName
                                      )}&reviewed=false`}
                                    >
                                      <dt className="text-sm font-medium text-gray-500">
                                        Left to Review
                                      </dt>
                                      <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                        <div className="underline text-blue-500">
                                          {programReviewing.assigned -
                                            programReviewing.reviewed}
                                        </div>
                                      </dd>
                                    </Link>
                                  </div>
                                </>
                              )}
                            </dl>
                          </div>

                          {listsReviewing
                            .filter(
                              (listReviewing) =>
                                listReviewing.programApplicationId ===
                                +programReviewing.id
                            )
                            .map((listsReviewing, index) => (
                              <Fragment key={index}>
                                {listsReviewing.name ===
                                "American Heart Association" ? (
                                  <div
                                    className={`col-span-2 min-h-[176px] p-4 rounded-lg border`}
                                  >
                                    <h3 className="text-base font-semibold text-gray-900">
                                      <Link
                                        href={`/user/${user.id}/applications/${listsReviewing.programApplicationId}?listId=${listsReviewing.id}`}
                                      >
                                        Curated List - {listsReviewing.name}
                                      </Link>
                                    </h3>
                                    <img
                                      src="/aha.png"
                                      className="w-full px-4"
                                    ></img>
                                    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                                      <div className="overflow-hidden rounded-lg bg-white p-2 shadow">
                                        <dt className="text-sm font-medium text-gray-500">
                                          Curated Applications
                                        </dt>
                                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                          <Link
                                            className="underline text-blue-500"
                                            href={`/user/${user.id}/applications/${listsReviewing.programApplicationId}?listId=${listsReviewing.id}`}
                                          >
                                            {listsReviewing.count}
                                          </Link>
                                        </dd>
                                      </div>
                                    </dl>
                                  </div>
                                ) : (
                                  <div
                                    className={`col-span-2 min-h-[176px] p-4 rounded-lg ${getProgramBackgroundColorById(
                                      listsReviewing.programApplicationId
                                    )}`}
                                  >
                                    {programReviewing.logoUrl && (
                                      <img
                                        src={programReviewing.logoUrl}
                                        className="max-h-16 px-4 mx-auto"
                                      ></img>
                                    )}
                                    <h3 className="text-base font-semibold text-gray-900">
                                      <Link
                                        href={`/user/${user.id}/applications/${listsReviewing.programApplicationId}?listId=${listsReviewing.id}`}
                                      >
                                        Curated List - {listsReviewing.name}
                                      </Link>
                                    </h3>
                                    {!programReviewing.logoUrl && (
                                      <p className="text-sm text-gray-700">
                                        {
                                          ProgramApplicationMappingIdToProgramOptions[
                                            listsReviewing.programApplicationId as keyof typeof ProgramApplicationMappingIdToProgramOptions
                                          ]
                                        }
                                      </p>
                                    )}
                                    {programReviewing.pitchEvents
                                      ?.filter(
                                        (pitchEvent: any) =>
                                          pitchEvent.shortLabel &&
                                          listsReviewing.name !== "ASPS" &&
                                          [3, 4].includes(
                                            +listsReviewing.programApplicationId
                                          )
                                      )
                                      .map((pitchEvent: any, index: number) => (
                                        <Fragment key={index}>
                                          <ul className="text-sm italic">
                                            <li key={index}>
                                              <Link
                                                href={`/user/${
                                                  user.id
                                                }/applications/${
                                                  programReviewing.id
                                                }?pitchEvent=${encodeURIComponent(
                                                  pitchEvent.name
                                                )}&listId=${listsReviewing.id}`}
                                                className="text-blue-600 underline"
                                              >
                                                Curated {pitchEvent.shortLabel}{" "}
                                                applications
                                              </Link>
                                            </li>
                                          </ul>
                                        </Fragment>
                                      ))}
                                    {[2].includes(
                                      +listsReviewing.programApplicationId
                                    ) && (
                                      <div className="text-sm">
                                        <div>
                                          Please review applications for each
                                          pitch event
                                        </div>
                                        {programReviewing.pitchEvents
                                          ?.filter(
                                            (pitchEvent: any) =>
                                              pitchEvent.label
                                          )
                                          .map(
                                            (
                                              pitchEvent: any,
                                              index: number
                                            ) => (
                                              <Fragment key={index}>
                                                <div className="font-bold pt-1">
                                                  {pitchEvent.label}
                                                </div>
                                                <ul className="italic list-disc list-inside pl-4">
                                                  <li key={index}>
                                                    <Link
                                                      href={`/user/${
                                                        user.id
                                                      }/applications/${
                                                        programReviewing.id
                                                      }?pitchEvent=${encodeURIComponent(
                                                        pitchEvent.name
                                                      )}&listId=${
                                                        listsReviewing.id
                                                      }`}
                                                      className="text-blue-600 underline"
                                                    >
                                                      Review Deadline:{" "}
                                                      {DateTime.fromSQL(
                                                        pitchEvent.partnerDeadlineDate
                                                      ).toFormat("LLLL d")}
                                                    </Link>
                                                  </li>
                                                </ul>
                                              </Fragment>
                                            )
                                          )}
                                      </div>
                                    )}
                                    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                                      <div className="overflow-hidden rounded-lg bg-white p-2 shadow">
                                        <dt className="text-sm font-medium text-gray-500">
                                          Curated Applications
                                        </dt>
                                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                          <Link
                                            className="underline text-blue-500"
                                            href={`/user/${user.id}/applications/${listsReviewing.programApplicationId}?listId=${listsReviewing.id}`}
                                          >
                                            {listsReviewing.count}
                                          </Link>
                                        </dd>
                                      </div>
                                    </dl>
                                  </div>
                                )}
                              </Fragment>
                            ))}

                          {/* {programReviewing.reviewersStats && (
                            <div className="col-span-2 min-h-[176px] ">
                              <ReviewerProgressWidget
                                key={programReviewing.id}
                                programName={programReviewing.title}
                                reviewers={programReviewing.reviewersStats}
                              />
                            </div>
                          )} */}

                          {user?.role !== RoleOptions.Admin && (
                            <div
                              className={`col-span-2 min-h-[176px] ${getProgramBackgroundColor(
                                programReviewing.title
                              )} p-4 rounded-lg`}
                            >
                              {programReviewing.logoUrl && (
                                <img
                                  src={programReviewing.logoUrl}
                                  className="max-h-16 px-4 mx-auto"
                                ></img>
                              )}
                              <h3 className="text-base font-semibold text-gray-900">
                                <Link
                                  href={`/user/${user.id}/applications/${programReviewing.id}/application-interests`}
                                >
                                  Application Expertise & Interests
                                </Link>
                              </h3>
                              {!programReviewing.interestsConfirmed && (
                                <Link
                                  href={`/user/${user.id}/applications/${programReviewing.id}/application-interests`}
                                  className="text-red-500 font-medium"
                                >
                                  Action Required
                                </Link>
                              )}
                              <p className="mt-2">
                                Get more revelant application assignments by
                                setting and confirming your{" "}
                                <Link
                                  href={`/user/${user.id}/applications/${programReviewing.id}/application-interests`}
                                  className="underline text-blue-500"
                                >
                                  expertise and interests
                                </Link>
                                .
                              </p>
                              {programReviewing?.phrases?.length &&
                              !programReviewing?.noPreference ? (
                                <div className="mt-2">
                                  Your interests{" "}
                                  <ul className="list-disc list-inside pl-4 text-gray-700">
                                    {programReviewing.phrases.map(
                                      (phrase: string, index: number) => (
                                        <li key={index}>{phrase}</li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              ) : (
                                <p className="italic mt-2 text-gray-700">
                                  You currently have no defined interests and
                                  will receive new application assignments at
                                  random
                                </p>
                              )}
                            </div>
                          )}
                        </Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {user?.role == RoleOptions.Company && (
                <>
                  {user?.company?.cohortMembership?.length > 0 ? (
                    <div>
                      {/* <div>
                            <dt className="text-base font-semibold leading-7 text-gray-900">
                              Slack - Quick Questions and Conversations
                            </dt>
                            <dd className="mt-2 text-base leading-7 text-gray-600">
                              <p>
                                Medtech Innovator Slack is the best place to
                                communicate in real time with other your
                                year&apos;s Cohort, all Alumni, and the extended
                                MedTech / BioTools Innovator ecosystem including
                                our partners, investors, and program judges. The
                                channels are invite-only channels, please let us
                                know if you did not receive an invitation to the
                                channels mentioned here.
                              </p>
                              <p>
                                <a
                                  href="https://join.slack.com/t/medtechinnovator/signup"
                                  className="font-semibold text-blue-600 hover:text-blue-500"
                                  target="_blank"
                                >
                                  Join Here
                                </a>
                              </p>
                            </dd>
                          </div> */}
                    </div>
                  ) : (
                    <div className="flex max-w-md flex-col items-center py-8 mx-auto">
                      {/* <p className="">
                        2025 Accelerator Programs are now accepting
                        applications.
                      </p> */}
                      <p className="p-4">
                        <Link
                          className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-3 py-1 text-sm font-medium text-white shadow-sm hover:bg-blue-700"
                          href={`/company/${user.companyId}/application`}
                        >
                          Apply Now
                        </Link>
                      </p>
                      <p>
                        Learn more about the various programs and speciality
                        tracks at their respective websites:
                      </p>
                      <ul
                        role="list"
                        className="divide-y divide-gray-200 w-full"
                      >
                        <li className="py-4">
                          <Link
                            className="flex underline justify-around items-center"
                            href="https://medtechinnovator.org/apply/"
                          >
                            <img
                              src="https://media.innovator.org/public/static/Medtech_Logo_Color.png"
                              className="h-16"
                            ></img>
                            MedTech Innovator
                          </Link>
                        </li>
                        <li className="py-4">
                          <Link
                            className="flex underline justify-around items-center"
                            href="https://biotoolsinnovator.org/apply/"
                          >
                            <img
                              src="https://media.innovator.org/public/static/bti-color-logo.png"
                              className="h-16"
                            ></img>
                            BioTools Innovator
                          </Link>
                        </li>
                        <li className="py-4">
                          <Link
                            className="flex underline justify-around items-center"
                            href="https://medtechinnovator.asia/apply-apac/"
                          >
                            <img
                              src="https://media.innovator.org/public/static/MedTech-Innovators-Asia-Pacific_MedTech_Asia-Pacific-Logo_Colour-1-1536x738.png"
                              className="h-16"
                            ></img>
                            MedTech Innovator APAC
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )}
                </>
              )}
              <div className="mx-auto max-w-7xl px-6 py-16 lg:px-8">
                <div className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
                  {user.openRoundsAccess && (
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <div className="relative pl-16">
                        <div>
                          <div className="text-base font-semibold leading-7 text-gray-900">
                            Open Rounds
                          </div>
                          <dd className="mt-2 text-base leading-7 text-gray-600">
                            <p>
                              We provide subscribing Investors with details on
                              MedTech Innovator Portfolio companies&apos; open
                              rounds in an easy-to-use interface.
                            </p>
                          </dd>
                        </div>
                        <div>
                          <dt className="text-base font-semibold leading-7 text-gray-900">
                            <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-600">
                              <CurrencyDollarIcon
                                aria-hidden="true"
                                className="h-6 w-6 text-white"
                              />
                            </div>
                          </dt>
                        </div>
                      </div>
                      <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8">
                        <div
                          className={classNames(
                            true
                              ? "ring-2 ring-blue-600"
                              : "ring-1 ring-gray-200",
                            "rounded-3xl p-8"
                          )}
                        >
                          <h3
                            className={classNames(
                              true ? "text-blue-600" : "text-gray-900",
                              "text-lg font-semibold leading-8"
                            )}
                          >
                            Open Rounds Early Access
                          </h3>
                          <p className="mt-4 text-sm leading-6 text-gray-600">
                            Pricing will be available after the conclusion of
                            the Early Access period.
                          </p>
                          <p className="mt-6 flex items-baseline gap-x-1">
                            <span className="text-4xl font-bold tracking-tight text-gray-900">
                              $0
                            </span>
                            <span className="text-sm font-semibold leading-6 text-gray-600">
                              /mo
                            </span>
                          </p>
                          <Link
                            href="/open-rounds"
                            className={classNames(
                              true
                                ? "bg-blue-600 text-white shadow-sm hover:bg-blue-500"
                                : "text-blue-600 ring-1 ring-inset ring-blue-200 hover:ring-blue-300",
                              "mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                            )}
                          >
                            Navigate to Open Rounds
                          </Link>
                          <ul
                            role="list"
                            className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
                          >
                            <li className="flex gap-x-3">
                              <CheckIcon
                                aria-hidden="true"
                                className="h-6 w-5 flex-none text-blue-600"
                              />
                              Accurate Information from the source
                            </li>
                            <li className="flex gap-x-3">
                              <CheckIcon
                                aria-hidden="true"
                                className="h-6 w-5 flex-none text-blue-600"
                              />
                              Structured data to filter out the noise
                            </li>
                            <li className="flex gap-x-3">
                              <CheckIcon
                                aria-hidden="true"
                                className="h-6 w-5 flex-none text-blue-600"
                              />
                              Saved filters and email notifications
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}

                  {user?.role == RoleOptions.Company &&
                    user?.company?.cohortMembership?.length > 0 && (
                      <div className="relative pl-16">
                        <div>
                          <div className="text-base font-semibold leading-7 text-gray-900">
                            Open Round
                          </div>
                          <dd className="mt-2 text-base leading-7 text-gray-600">
                            <p>
                              Open Rounds is your secret weapon to raise capital
                              faster and from better investors. We exclusively
                              provide subscribing Investors with details on Open
                              Rounds within our Portfolio. You control exactly
                              what investors can see about your company,
                              product, and Open Round. This information should
                              be reviewed and updated as necessary. The
                              following categories of information should be
                              completed to ensure you match filtering criteria
                              we provide investors.
                            </p>
                          </dd>
                        </div>
                        <div>
                          <dt className="text-base font-semibold leading-7 text-gray-900">
                            <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-600">
                              <IdentificationIcon
                                aria-hidden="true"
                                className="h-6 w-6 text-white"
                              />
                            </div>
                          </dt>
                          <dd className="mt-2 text-base leading-7 text-gray-600">
                            <Link
                              href={`/company/${user.companyId}/open-round`}
                              className="font-semibold text-blue-600 hover:text-blue-500"
                            >
                              Open Round
                            </Link>
                          </dd>
                        </div>
                      </div>
                    )}

                  {isPortfolioCompanyUserOrPartner(user) && (
                    <div className="relative pl-16">
                      <div>
                        <div className="text-base font-semibold leading-7 text-gray-900">
                          Portfolio Directory
                        </div>
                        <dd className="mt-2 text-base leading-7 text-gray-600">
                          <p>
                            Comprehensive list of all Innovator Portfolio
                            companies, summaries, videos and contact
                            information. This directory is powered by live data
                            provided by the companies themselves.
                          </p>
                        </dd>
                      </div>
                      <div>
                        <dt className="text-base font-semibold leading-7 text-gray-900">
                          <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-600">
                            <IdentificationIcon
                              aria-hidden="true"
                              className="h-6 w-6 text-white"
                            />
                          </div>
                        </dt>
                        <dd className="mt-2 text-base leading-7 text-gray-600">
                          <Link
                            href={`/portfolio-directory`}
                            className="font-semibold text-blue-600 hover:text-blue-500"
                          >
                            Navigate to directory
                          </Link>
                        </dd>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {user?.role && user.role == RoleOptions.Investor && (
                <div>
                  {!user.openRoundsAccess && (
                    <>
                      <div className="flex py-8 flex-col text-center">
                        <p>
                          You are not yet configured for Open Rounds access.
                        </p>
                        <p>
                          Please reach out to support at{" "}
                          <a
                            href="mailto:support@medtechinnovator.org"
                            className="font-semibold text-blue-600 hover:text-blue-500"
                          >
                            support@medtechinnovator.org
                          </a>{" "}
                          or{" "}
                          <a
                            className="font-semibold text-blue-600 hover:text-blue-500 hover:cursor-pointer"
                            onClick={() => {
                              showNewMessage(
                                "Hi, I'm an investor and need help getting access to Open Rounds."
                              );
                            }}
                          >
                            chat with us
                          </a>
                          .
                        </p>
                      </div>
                      <div className="hidden lg:block">
                        <InvestorMarketingSection />
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </Layout>
        </main>
      ) : (
        <PageLoading />
      )}
    </>
  );
};
export default withNoSSR(Home);
